<template>
  <UpsertForm :editMode='true'/>
</template>

<script>
  import UpsertForm from '../components/categoryImages/UpsertForm.vue';
  export default {
    name: "Update",
    components: {
      UpsertForm
    }
  }
</script>

<style scoped>

</style>