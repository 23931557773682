<template>
  <div class="p-grid">
    <Loading :is-loading="isLoading" />
    <div class="p-col-4">
      <h5>Image File</h5>
      <FileUpload
        name="demo[]"
        url="./upload"
        @FileUploadFileSelected="imageUpload"
      />
      <img class="image-preview" :src="fileLocation" alt="" />
    </div>
    <div class="p-col-4">
      <div class="p-fluid">
        <div class="p-field">
          <h4 class="field-label" for="brandField">
            <span class="required-field">*</span>Brand
          </h4>
          <BrandDropdown v-model="selectedBrand" />
        </div>
        <div class="p-field">
          <h4 class="field-label" for="categoryField">
            <span class="required-field">*</span>Image Categories
          </h4>
          <CategoriesDropdown
            v-model="selectedCategory"
            @category-selected="AssignCategory"
          />
          <div v-if="categoryList.length > 0" id="selected-categories">
            <label>Selected Categories</label>
            <CategoryList
              :items="categoryList"
              :iconA="{
                iconClass: 'pi pi-trash',
                action: 'remove-category',
              }"
              @remove-category="RemoveCategory"
              class="category-list"
            />
          </div>
        </div>
        <div class="p-field">
          <h4 class="field-label">Image status</h4>
          <div class="image-status">
            <label for="statusField">Active</label>
            <Checkbox
              id="statusField"
              v-model="imageStatus"
              :binary="true"
              value="Active"
            />
          </div>
        </div>
        <div class="p-field">
          <h4 class="field-label" for="legendTitleField">
            <span class="required-field">*</span>Legend Title
          </h4>
          <InputText id="legendTitleField" v-model="legendTitle"></InputText>
        </div>
        <div class="p-field">
          <h4 class="field-label" for="alternateTextField">
            <span class="required-field">*</span>Image Alternate Text
          </h4>
          <InputText
            id="alternateTextField"
            v-model="alternateText"
          ></InputText>
        </div>
        <div class="p-field">
          <h4 class="field-label" for="imageOrderField">
            <span class="required-field">*</span>Image Order
          </h4>
          <InputNumber v-model="imageOrder" id="imageOrderField"></InputNumber>
        </div>
        <div class="p-field">
          <h4 class="field-label" for="imageTypeField">
            <span class="required-field">*</span>Image Type
          </h4>
          <AutoComplete
            id="imageTypeField"
            placeholder="Select an Image Type"
            :listOfSuggestions="filterImageType"
            v-model="selectedImageType"
            displayField="imageType"
          />
        </div>
        <Button
          :name="editMode ? 'Update' : 'Create'"
          @click="saveImage"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from "vue";
import { useStore } from "vuex";
import "primeflex/primeflex.css";
import AutoComplete from "../base/BaseAutoCompleteDropDown.vue";
import Button from "../base/BaseButton.vue";
import CategoryList from "../base/BaseItemList.vue";
import Checkbox from "primevue/checkbox";
import FileUpload from "../base/BaseImageUploader.vue";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import BrandDropdown from "../BrandsAndCategories/BrandsAutoCompleteDropdown.vue";
import CategoriesDropdown from "../BrandsAndCategories/CategoriesAutoCompleteDropdown.vue";
import AxiosService from "../../api/AxiosService";
import ToastHandler from "../../helpers/toastHandler";
import Loading from "../base/Loading.vue";
import { useRouter } from "vue-router";

export default {
  name: "UpsertForm",
  components: {
    AutoComplete,
    Button,
    CategoryList,
    Checkbox,
    FileUpload,
    InputText,
    InputNumber,
    BrandDropdown,
    CategoriesDropdown,
    Loading,
  },
  props: {
    editMode: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup(props) {
    const { editMode } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const { displayErrorToast, displaySuccessToast } = ToastHandler();

    const imageData = ref(null);

    const categoryList = ref([]);

    //Category dropdown
    const filterCategory = ref([]);
    const selectedCategory = ref(null);

    //Brand dropdown
    const filterBrand = ref([]);
    const selectedBrand = ref(store.getters["categoryImages/getSelectedBrand"]);

    let imageStatus = ref(false);

    let legendTitle = ref(null);
    let alternateText = ref(null);
    let imageOrder = ref(null);
    let fileLocation = ref(null);
    //Image Type dropdown
    const filterImageType = ref([]);

    let selectedImageType = ref(null);

    const isLoading = ref(false);

    if (editMode.value) {
      imageData.value = store.getters["categoryImages/getImageData"];
      if (imageData.value) {
        imageStatus.value =
          imageData.value.imageStatusGu.imageStatus.toLowerCase() === "active";
        legendTitle.value = imageData.value.legendTitle;
        alternateText.value = imageData.value.imageAltText;
        imageOrder.value = imageData.value.imageOrder;
        fileLocation.value = imageData.value.fileLocation;
        categoryList.value = imageData.value.imageCategory.map((el) => ({
          id: el.categoryGu.categoryGuid,
          title: el.categoryGu.categoryName,
        }));
      }
    }

    const AssignCategory = () => {
      //If the categoryList already contains the category selected, don't add it again.
      if (
        !categoryList.value.some(
          (c) => c.id === selectedCategory.value.categoryGuid
        )
      )
        categoryList.value.push({
          id: selectedCategory.value.categoryGuid,
          title: selectedCategory.value.categoryName,
        });

      selectedCategory.value = null;
    };

    const RemoveCategory = (event) => {
      categoryList.value = categoryList.value.filter(
        (category) => category.id !== event
      );
    };

    const imageFile = ref(null);
    const imageUpload = (event) => {
      imageFile.value = event.files[0];
      fileLocation.value = imageFile.value.objectURL;
    };

    const getImageTypes = async () => {
      filterImageType.value = await AxiosService.getImageTypes();
      if (editMode.value && imageData.value) {
        selectedImageType.value = filterImageType.value.find(
          (x) => x.imageTypeGuid == imageData.value.imageTypeGuid
        );
      }
    };

    const doValidations = () => {
      let isValid = true;
      let message = "";
      if (
        !selectedBrand.value ||
        !categoryList.value.length > 0 ||
        !legendTitle.value ||
        !alternateText.value ||
        !imageOrder.value ||
        !selectedImageType.value
      ) {
        message = "Please add all the required fields before creating";
        isValid = false;
      } else if (!editMode.value && !imageFile.value) {
        message = "Please choose and upload an image before creating";
        isValid = false;
      }
      if (!isValid) {
        displayErrorToast("Required Fields", message);
      }

      return isValid;
    };

    const saveImage = async () => {
      try {
        isLoading.value = true;
        if (doValidations()) {
          const data = {
            imageFile: imageFile.value,
            legendTitle: legendTitle.value,
            imageAltText: alternateText.value,
            imageOrder: imageOrder.value,
            imageStatus: imageStatus.value,
            imageType: selectedImageType.value.imageType,
            imageCategories: categoryList.value,
            brand: selectedBrand.value,
            imageGuid: editMode.value ? imageData.value.imageGuid : null,
            createdDate: editMode.value ? imageData.value.createdDate : null,
          };

          let newImage = null;

          if (editMode.value) {
            newImage = await AxiosService.putImage(data);
          } else {
            newImage = await AxiosService.postImage(data);
          }

          displaySuccessToast(
            `Image ${editMode.value ? "Updated" : "Created"}`,
            `Your Image has been ${editMode.value ? "Updated" : "Created"}`
          );

          const img = await AxiosService.getImageData(newImage.imageGuid);
          store.commit("categoryImages/setImageData", img);
          router.push({
            name: "Details",
          });
        }
      } finally {
        isLoading.value = false;
      }
    };

    getImageTypes();

    return {
      categoryList,
      filterCategory,
      selectedCategory,
      filterBrand,
      selectedBrand,
      AssignCategory,
      RemoveCategory,
      imageStatus,
      legendTitle,
      alternateText,
      imageOrder,
      filterImageType,
      selectedImageType,
      fileLocation,
      isLoading,
      imageUpload,
      saveImage,
    };
  },
};
</script>

<style scoped>
.field-label {
  text-align: left;
  margin-bottom: 3px;
}
.image-status {
  display: flex;
}

.image-status .p-checkbox {
  margin-left: 5px;
}

.p-field {
  margin-bottom: 2rem;
}

#btn-add-category {
  margin-top: 5px;
}

.image-preview {
  max-width: 100%;
}

.required-field {
  color: red;
}

#selected-categories {
  margin-top: 10px;
  border: 2px solid;
  border-color: lightgray;
  margin-right: 40px;
}

#selected-categories label {
  float: left;
  margin: 10px 0px 5px 10px;
  font-weight: 700;
}

#selected-categories .category-list {
  clear: both;
}
</style>