<template>
  <div class="p-grid">
    <div class="p-col-4 p-offset-1">
      <router-link to="/" class="back-to-list">
        <Button label="< Back To List" />
      </router-link>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-4">
      <img class="img-field" :src="imageData.fileLocation" />
    </div>
    <div class="p-col-8">
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Brand</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">{{ selectedBrand.brandName }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Categories</div>
        </div>
        <div class="p-col-10">
          <div
            v-for="(cat, idx) in imageData.imageCategory"
            :key="idx"
            class="category-field-values"
          >
            {{ cat.categoryGu.categoryName }}
          </div>
        </div>
      </div>

      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Image Alternate Text</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">{{ imageData.imageAltText }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">File Name</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">{{ imageData.fileName }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Image Order</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">{{ imageData.imageOrder }}</div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Created Date</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">
            <BaseDateTime
              :date-value="new Date(imageData.createdDate)"
              :display-time="true"
            />
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Created By User</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">
            {{ imageData.createdbyUser }}
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Updated Date</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">
            <BaseDateTime
              :date-value="new Date(imageData.updatedDate)"
              :display-time="true"
            />
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Updated By User</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">
            {{ imageData.updatedbyUser }}
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Image Status</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">
            {{ imageData.imageStatusGu.imageStatus }}
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-2">
          <div class="lbl-field-names">Image Type</div>
        </div>
        <div class="p-col-10">
          <div class="field-values">
            {{ imageData.imageTypeGu.imageType }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import "primeflex/primeflex.css";
import BaseDateTime from "../components/base/BaseDateTime.vue";
import Button from "primevue/button";

export default {
  name: "Update",
  components: {
    BaseDateTime,
    Button,
  },
  setup() {
    const store = useStore();
    const selectedBrand = ref(store.getters["categoryImages/getSelectedBrand"]);
    const imageData = ref(store.getters["categoryImages/getImageData"]);

    return { imageData, selectedBrand };
  },
};
</script>

<style scoped>
.lbl-field-names {
  text-align: left;
  font-weight: bold;
}

.field-values {
  text-align: left;
}

.category-field-values {
  margin-bottom: 5px;
  text-align: left;
}

.img-field {
  max-width: 100%;
}

.back-to-list {
  clear: both;
  float: left;
  margin-bottom: 10px;
  text-decoration: none;
}

.back-to-list .p-button {
  background: lightgray;
  color: black;
  border: none;
}
</style>