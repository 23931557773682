<template>
  <div>       
        <br/>
        <FileUpload name="demo[]" :customUpload="true" @uploader="onUpload" :multiple="false" 
          accept="image/*" :maxFileSize="1000000" :fileLimit=10 >
          <template #empty>
              <p>Drag and drop files to here to upload.</p>
          </template>
          </FileUpload>
  </div>
</template>

<script>
import FileUpload from 'primevue/fileupload';
export default {
  name: "upload-files",
  components: {
      FileUpload
  },
  data() {
    return {
        imageFile: null,
        imageSrc: null,

    };
  },
  methods: {
      onUpload(event){
        this.imageFile = event.files[0]
        
        //Set the preview image
        this.imageSrc = this.imageFile.objectURL  
        //Emit the selected file to the parent component
        this.$emit("FileUploadFileSelected", event)
      },
  },
};
</script>

<style scoped>
</style>