<template>
  <table>
    <tr v-for="item in items" v-bind:key="item.id" >
      <td>
        {{item.title}}
      </td>
      <td>
        <i @click="$emit(iconA.action, item.id)" :class="iconA.iconClass"></i>
      </td>
      <td>
        <i @click="$emit(iconB.action, item.id)" :class="iconB.iconClass"></i>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "BaseItemList",
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    },
    iconA: {
      type: Object,
      default: function() {
        return {}
      }
    },
    iconB: {
      type: Object,
      default: function() {
        return {}
      }
    }
  }
};
</script>

<style scoped>
td {
  padding: 10px;
  text-align: left;    
}
i {
  cursor: pointer;
}
</style>